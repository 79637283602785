import React from "react";
import Loader from "react-loader-spinner";

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { height, width } = this.props;
    return (
      <div className="loading-component">
        <div className="loader-parent">
          <Loader
            type="Triangle"
            color="#363636"
            height={height || 100}
            width={width || 100}
            // timeout={3000} //3 secs
          />
          <p className="loading-message">Loading...</p>
        </div>
      </div>
    );
  }
}
