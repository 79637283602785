import React, { useEffect } from "react";
import routes from "./routes";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loading from "./common/Loading";

function App() {
  return (
    <div className="App">
      <Router>
        <React.Suspense fallback={<Loading />}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                )
              );
            })}
            <Redirect to="/" />
          </Switch>
        </React.Suspense>
      </Router>
    </div>
  );
}

export default App;
